/** @jsx jsx */
import { CircularProgress, FormControlLabel, Grid, Switch } from '@material-ui/core';
import React from 'react';
import { jsx, SxStyleProp } from 'theme-ui';
import { SalesByDay } from '../../../api';
import { graphTitleCss, loaderCss } from '../../../styles';
import {
  formatTotalSalesForGraph,
  getAvgMarkers,
  userCanSeeFinancialInfo
} from '../../../utils';
import { GraphContainer } from '../../graphContainer';
import { LineGraph } from '../../lineGraph';

interface SalesSectionProps {
  totalSales: SalesByDay[];
  totalUnits: SalesByDay[];
  isLoading: boolean;
}

//Component Styles
const byDayGraphFlex: SxStyleProp = {
  display: 'flex',
  justifyContent: 'flex-start'
};
const byDayMl: SxStyleProp = {
  marginLeft: '1rem'
};

const canSeeFinancial = userCanSeeFinancialInfo();

export function SalesByDayGraphSection(props: SalesSectionProps) {
  const { totalSales, totalUnits, isLoading } = props;
  const [showUnits, setShowUnits] = React.useState<boolean>(!canSeeFinancial);
  const [showAvg, setShowAvg] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      {!!totalSales.length && !!totalUnits.length && (
        <Grid item xs={12}>
          <GraphContainer>
            <div sx={byDayGraphFlex}>
              {canSeeFinancial && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={showUnits}
                      onChange={(e, c) => setShowUnits(c)}
                      color="primary"
                    />
                  }
                  label={`Mostrar ${showUnits ? 'facturacion' : 'unidades'}`}
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={showAvg}
                    onChange={(e, c) => setShowAvg(c)}
                    color="primary"
                  />
                }
                label={`${showAvg ? 'Ocultar' : 'Mostrar'} promedios`}
                sx={byDayMl}
              />
            </div>
            <h2 sx={graphTitleCss}>
              {!showUnits ? 'Facturacion' : 'Unidades vendidas'} por dia
            </h2>
            {isLoading && <CircularProgress sx={loaderCss} color="primary" />}
            {!!totalSales.length && (
              <LineGraph
                data={formatTotalSalesForGraph(showUnits ? totalUnits : totalSales)}
                yFormat={value =>
                  showUnits ? parseInt(value.toString(), 10) : `$${value}`
                }
                isMoney={!showUnits}
                markers={
                  showAvg
                    ? getAvgMarkers(showUnits ? totalUnits : totalSales, showUnits)
                    : []
                }
              />
            )}
          </GraphContainer>
        </Grid>
      )}
    </React.Fragment>
  );
}
