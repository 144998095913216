import { LoginRequest, LoginResponse, UsersApi } from '../api';
import { Routes } from '../const';
import { Roles } from '../models';

export const AUTH_TOKEN_NAME = 'accessToken';
export const ROLE_NAME = 'roles';

export const accessPerRole = {
  [Roles.StockManager]: [Routes.Stock, Routes.Login],
  [Roles.SuperAdmin]: [
    Routes.Dashboard,
    Routes.ExpensesCategories,
    Routes.Stock,
    Routes.MonthlySales,
    Routes.Comparisons,
    Routes.Expenses,
    Routes.Login,
    Routes.SalesLocations,
    Routes.OrdersLocations,
    Routes.GeneralReport,
    Routes.TransactionsReport,
    Routes.Certification,
    Routes.ProductsSold,
    Routes.PaymentsReport,
    Routes.SalesByCategorySummary
  ],
  [Roles.Marketing]: [
    Routes.SalesLocations,
    Routes.Login,
    Routes.Dashboard,
    Routes.OrdersLocations
  ],
  [Roles.BranchManager]: [
    Routes.Dashboard,
    Routes.Login,
    Routes.SalesLocations,
    Routes.Stock,
    Routes.MonthlySales,
    Routes.Certification,
    Routes.OrdersLocations,
    Routes.Comparisons
  ],
  [Roles.BranchSupervisor]: [
    Routes.Dashboard,
    Routes.Login,
    Routes.SalesLocations,
    Routes.Certification,
    Routes.Stock,
    Routes.MonthlySales,
    Routes.OrdersLocations
  ],
  [Roles.Delivery]: [Routes.OrdersLocations, Routes.Login],
  [Roles.ExpensesDataEntry]: [Routes.Expenses, Routes.TransactionsReport,Routes.Certification, Routes.Login],
  [Roles.Client]: [Routes.Login]
};

export function removeAuthToken() {
  localStorage.removeItem(AUTH_TOKEN_NAME);
}

export function setUserInfo(userInfo: LoginResponse) {
  localStorage.setItem(AUTH_TOKEN_NAME, userInfo.token as string);
  if (userInfo.roles) localStorage.setItem(ROLE_NAME, userInfo.roles.join(','));
}

export function getUserRole(): Roles {
  const roleString = localStorage.getItem(ROLE_NAME)?.split(',')[0] ?? null;
  return roleString as Roles;
}

export function getAuthToken() {
  const token = localStorage.getItem(AUTH_TOKEN_NAME);
  if (token) return 'Bearer ' + localStorage.getItem(AUTH_TOKEN_NAME);
  return null;
}

export async function login(userName: string, password: string) {
  const loginInfo = await UsersApi.login(new LoginRequest({ userName, password }));
  setUserInfo(loginInfo);
}

export function isAuthorized() {
  return !!(getAuthToken() && getUserRole());
}

/** Can the loggedIn user access to financial information? */
export function userCanSeeFinancialInfo() {
  const role = getUserRole();
  const rolesWithFinancialAccess = [Roles.SuperAdmin];
  return rolesWithFinancialAccess.includes(role);
}

/** Can the loggedIn user update expenses? */
export function userCanEditExpenses() {
  const role = getUserRole();
  const rolesWithFinancialAccess = [Roles.SuperAdmin];
  return rolesWithFinancialAccess.includes(role);
}
