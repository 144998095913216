import { IExpenseDTO } from '../../../api';
import { useExpensesState } from '../../../contexts';

export function useExpenseUpdate() {
  const [expState, setExpState] = useExpensesState();

  const updateState = (modifiedExpense: IExpenseDTO) => {
    const valueIndex = expState.expenses.values.findIndex(
      e => e.id === modifiedExpense.id
    );
    if (valueIndex > -1) {
      expState.expenses.values[valueIndex] = modifiedExpense;
    } else expState.expenses.values.unshift(modifiedExpense);
    setExpState(expState);
  };
  return { updateState, expState };
}
