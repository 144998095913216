/** @jsx jsx */
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { jsx } from 'theme-ui';
import { Routes } from '../../const';
import { accessPerRole, getAuthToken, getUserRole } from '../../utils';
import { Layout } from '../layout';
import { Roles } from '../../models';

interface PrivateRouteProps {
  component: React.FC<RouteComponentProps>;
  path: string;
  exact: boolean;
}

export function PrivateRoute(props: PrivateRouteProps) {
  const { component: Component, path, exact } = props;
  return (
    <Route
      path={path}
      exact={exact}
      render={props => {
        const userRole = getUserRole();
        if (!getAuthToken() || !userRole || !accessPerRole[userRole])
          return <Redirect to={Routes.Login} />;
        if (!accessPerRole[userRole].includes((path as unknown) as Routes))
          return <Redirect to={accessPerRole[userRole][0]} />;
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}
