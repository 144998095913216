import { ProductsSold } from '../../../../api';
import { Pos } from '../../../../models';

interface ProductsSoldRow {
  name: string;
  reference: string;
  [Pos.PaseoSalta]?: number;
  [Pos.Belgrano]?: number;
  [Pos.Bicentenario]?: number;
  [Pos.GrandBourg]?: number;
  [Pos.ZonaSur]?: number;
  [Pos.Freezados]?: number;
  [Pos.Todos]?: number;
  [Pos.Factory]?: number;
  freezados?: number;
}

export function formatProductsSoldForTable(
  data: ProductsSold[],
  showRevenue: boolean
): ProductsSoldRow[] {
  const response: ProductsSoldRow[] = [];

  data.forEach(productSold => {
    productSold.sales?.forEach(s => {
      const rowIndex = response.findIndex(p => p.reference === s.reference);
      if (rowIndex === -1)
        response.push({
          reference: s.reference as string,
          name: s.name?.replace('1-', '') as string,
          [productSold.pointOfSale as Pos]: showRevenue ? s.revenue : s.units,
          // Using Todos as the aggregation key to show total values
          [Pos.Todos]: showRevenue ? s.revenue : s.units
        });
      else {
        response[rowIndex][productSold.pointOfSale as Pos] = showRevenue
          ? s.revenue
          : s.units;
        response[rowIndex][Pos.Todos] =
          response[rowIndex][Pos.Todos]! + (showRevenue ? s.revenue : s.units);
      }
    });
  });

  console.log('format result is', response)
  return response;
}


