/**@jsx jsx */
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useExpensesState } from '../../../contexts';
import {
  ICreateExpenseCatRequest,
  IExpenseCategoryDTO,
  ExpensesCategoryApi,
  ExpenseCategoryDTO,
  CreateExpenseCatRequest,
  UpdateExpenseCatRequest
} from '../../../api';
import { useSnackbar } from 'notistack';

export function useExpenseCategoriesRHF(
  selectedCategory?: IExpenseCategoryDTO,
  onSubmit?: (category: IExpenseCategoryDTO) => void
) {
  const { enqueueSnackbar } = useSnackbar();
  const categories = useExpensesState()[0].categories.values;

  const schema = yup.object().shape<ICreateExpenseCatRequest>({
    name: yup.string().required('Requerido').max(70, 'No mas de 70 caracteres'),
    description: yup.string().max(150, 'No mas de 150 caracteres'),
    // @ts-ignore
    parentCategoryId: yup
      .mixed()
      .test('categoriaValida', 'Debe elegir una categoria valida', val => {
        if (!val) return true;
        if (selectedCategory?.id === val) return false;
        return categories.map(c => c.id).includes(val);
      })
  });

  const hookform = useForm<ICreateExpenseCatRequest>({
    defaultValues: {
      name: selectedCategory?.name ?? '',
      description: selectedCategory?.description ?? '',
      parentCategoryId: selectedCategory?.category?.id ?? undefined
    },
    resolver: yupResolver(schema)
  });

  const onFormSubit = async (values: ICreateExpenseCatRequest) => {
    try {
      let updatedCategory: ExpenseCategoryDTO;
      if (!selectedCategory?.id) {
        updatedCategory = await ExpensesCategoryApi.createExpenseCategory(
          new CreateExpenseCatRequest(values)
        );
      } else {
        const request = new UpdateExpenseCatRequest();
        request.init({
          ...values,
          expenseCategoryId: selectedCategory.id
        });
        updatedCategory = await ExpensesCategoryApi.updateExpenseCategory(request);
      }
      enqueueSnackbar(
        `Datos ${!selectedCategory?.id ? 'guardados' : 'modificados'} con exito`,
        {
          variant: 'success'
        }
      );
      if (onSubmit) onSubmit(updatedCategory);
      hookform.reset();
    } catch (error) {
      enqueueSnackbar('Algo salio mal al intentar guardar los datos 😢', {
        variant: 'error'
      });
    }
  };

  return { onFormSubit, categories, hookform };
}
