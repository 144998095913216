/**@jsx jsx */
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { Flex, jsx } from 'theme-ui';
import { DateInput } from '../dateInput';
import { SxStyleProp } from 'theme-ui';

interface FilterProps {
  selectedFromDate: ParsableDate;
  selectedToDate: ParsableDate;
  setSelectedFromDate: (value: React.SetStateAction<Date>) => void;
  setSelectedToDate: (value: React.SetStateAction<Date>) => void;
}

//Component Styles
const filterWrapper: SxStyleProp = {
  flexDirection: ['column', 'row']
};
export const fromInput: SxStyleProp = {
  width: ['100%', '12rem']
};
const toInput: SxStyleProp = {
  ml: [0, '2rem'],
  mt: ['1rem', 0],
  width: ['100%', '12rem']
};

export function Filters(props: FilterProps) {
  const {
    selectedFromDate,
    selectedToDate,
    setSelectedFromDate,
    setSelectedToDate
  } = props;

  return (
    <Flex sx={filterWrapper}>
      <DateInput
        sx={fromInput}
        onChange={d => setSelectedFromDate(d as Date)}
        value={selectedFromDate}
        label="Desde:"
      />
      <DateInput
        sx={toInput}
        onChange={d => setSelectedToDate(d as Date)}
        value={selectedToDate}
        label="Hasta:"
      />
    </Flex>
  );
}
