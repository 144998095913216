import { useExpensesState } from '../../../contexts';
import { IExpenseCategoryDTO, ExpenseCategoryDTO } from '../../../api';

export function useCategoryUpdate(callback?: () => void) {
  const [expState, setExpState] = useExpensesState();
  const categories = expState.categories.values;

  const handleCategoryUpdate = (modifiedCat: IExpenseCategoryDTO) => {
    let hasModified = undefined;
    expState.categories.values = categories.map(categ => {
      if (categ.id === modifiedCat.id) {
        hasModified = true;
        return modifiedCat;
      }
      if (categ.category?.id === modifiedCat.id) {
        categ.category = modifiedCat as ExpenseCategoryDTO;
      }
      return categ;
    });
    if (!hasModified) categories.unshift(modifiedCat);
    setExpState(expState);
    if (callback) callback();
  };

  const handleCategoryDeletion = (cat: IExpenseCategoryDTO) => {
    expState.categories.values = categories.filter(c => c.id !== cat.id);
    setExpState(expState);
  };

  return { handleCategoryUpdate, handleCategoryDeletion };
}
