/** @jsx jsx */
import { Button, Container, Grid, LinearProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { Flex, jsx } from 'theme-ui';
import { Combobox, CustomSnackBar, Filters, UnitSoldSection } from '../../components';
import { PosCategories } from '../../const';
import * as css from './productSoldCss';
import { useProductsSold } from './useProductsSold';

export function ProductSold() {
  const initalDate = new Date();
  initalDate.setDate(initalDate.getDate() - 7);
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date>(initalDate);
  const [selectedToDate, setSelectedToDate] = React.useState<Date>(new Date());
  const [category, setCategory] = useState(PosCategories.EMPANADAS);
  const fromDate = selectedFromDate.toISOString().substring(0, 10);
  const toDate = selectedToDate.toISOString().substring(0, 10);

  const {
    error,
    setError,
    isLoadingUnits,
    getUnits,
    clear,
    productsSold
  } = useProductsSold(fromDate, toDate, category);

  return (
    <React.Fragment>
      {error && (
        <CustomSnackBar
          message={error}
          variant="error"
          verticalAlignment="top"
          open={!!error}
          horizontalAlignment="center"
          onClose={() => setError('')}
        />
      )}
      <Container sx={css.salesContainer}>
        <Flex sx={css.salesContainerFlex}>
          <Filters
            selectedFromDate={selectedFromDate}
            setSelectedToDate={setSelectedToDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
          />
          <Combobox
            name="category"
            options={Object.values(PosCategories)}
            getOptionLabel={(c: string) => c ?? ''}
            onChange={(e, v) => {
              setCategory(v as PosCategories);
              clear();
            }}
            sx={{ width: ['15rem'], ml: '1rem' }}
            value={category}
            placeholder="Seleccionar categoria"
            label="Categoria"
          />
          <Grid item lg={2}>
            <Button
              onClick={getUnits}
              sx={css.salesButton}
              color="primary"
              variant="contained"
            >
              CONSULTAR VENTAS
            </Button>
          </Grid>
        </Flex>
        <Grid container>{isLoadingUnits && <LinearProgress sx={css.progressBar} />}</Grid>

        <Grid container spacing={2}>
          <UnitSoldSection productsSold={productsSold} isLoading={isLoadingUnits} />
        </Grid>
      </Container>
    </React.Fragment>
  );
}
