import { SxStyleProp } from 'theme-ui';

export const graphWrapper: SxStyleProp = {
  padding: ['1rem', '2rem'],
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
  minHeight: '28.125rem',
  width: '100%',
  position: 'relative'
};

export const title: SxStyleProp = {
  flexGrow: 1
};

export const content: SxStyleProp = {
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto'
};

export const salesLocationContainer: SxStyleProp = {
  paddingTop: '1rem',
  paddingBottom: '2rem',
  maxWidth: '100vw'
};

export const salesLocationContainerFlex: SxStyleProp = {
  flexDirection: ['column', 'row'],
  padding: '1rem 0',
  alignItems: 'center'
};

export const graphTitle: SxStyleProp = {
  marginTop: 0
};

export const loader: SxStyleProp = {
  top: '50%',
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)'
};

export const salesLocationButton: SxStyleProp = {
  marginTop: '1.5rem',
  marginLeft: '1rem',
  marginBottom: ['1rem', '0rem']
};

export const selectorContainer: SxStyleProp = {
  display: 'flex',
  alignItems: 'center'
};

export const formControlContainer: SxStyleProp = {
  width: '8rem'
};
