import { useFetchEntityList } from './useEntityList';
import { ExpensesCategoryApi, BranchesApi } from '../api';
import { useEffect } from 'react';
import { useExpensesState } from '../contexts';

export function useInitializeExpenses() {
  const [expensesState, setExpensesState] = useExpensesState();
  const [categories, isLoadingCategories] = useFetchEntityList(
    ExpensesCategoryApi.getExpenseCategories.bind(ExpensesCategoryApi)
  );
  const [branches, isLoadingBranches] = useFetchEntityList(
    BranchesApi.getBranches.bind(BranchesApi)
  );

  // Initialize commonly used values
  useEffect(() => {
    expensesState.categories.values = categories;
    expensesState.categories.isLoading = isLoadingCategories;
    expensesState.branches.values = branches;
    expensesState.branches.isLoading = isLoadingBranches;
    setExpensesState(expensesState);
  }, [categories.length, branches.length, isLoadingCategories, isLoadingBranches]);

  return expensesState;
}
