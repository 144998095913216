/** @jsx jsx */
import { jsx, SxStyleProp } from 'theme-ui';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { HighLight } from '../../models/highlight';
import { generalColors } from '../../utils';

interface HighLightedTableProps {
  highlights: HighLight[];
  title: string;
  className?: string;
}

const headerCss: SxStyleProp = {
  backgroundColor: generalColors.GREY,
  textAlign: 'center',
  fontWeight: 'bold'
};

const valueCss: SxStyleProp = {
  borderLeft: 1,
  borderLeftStyle: 'solid',
  borderLeftColor: generalColors.GREY,
  textAlign: 'center',
  fontWeight: 'bold'
};

const tableCss: SxStyleProp = {
  margin: '15px'
};

export function HighLightedTable(props: HighLightedTableProps) {
  const { highlights, title, className } = props;

  var titles: string[] = highlights?.length ? highlights.map(x => x.title ?? '') : [];
  return (
    <div className={className}>
      <h3 sx={{ textAlign: 'center' }}>{title}</h3>
      <TableContainer component={Paper} sx={tableCss}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {titles.map(title => (
                <TableCell sx={headerCss}>{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {highlights.map(x => (
                <TableCell sx={valueCss} component="th" scope="row">
                  {x.value}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
