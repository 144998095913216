import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { AuthVerifier, PrivateRoute } from './components';
import { Routes } from './const';
import { BussinesPulseContextProvider } from './contexts';
import {
  Comparison,
  Expenses,
  ExpensesCategories,
  GeneralReport,
  Login,
  PaymentsReport,
  SalesByCategoryReport,
  StockControl,
  StockReport,
  TransactionReport
} from './pages';
import { CertificationPage } from './pages/certification';
import { OrdersLocations } from './pages/ordersLocation';
import { ProductSold } from './pages/productsSold';
import { Dashboard } from './pages/sales';
import { SalesByMonth } from './pages/salesByMonth';
import { SalesLocations } from './pages/salesLocations';
import * as serviceWorker from './serviceWorker';
import appTheme from './styles';

function Root() {
  return (
    <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
      <BussinesPulseContextProvider>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <BrowserRouter>
            <AuthVerifier />
            <Route exact path={Routes.Login} component={Login} />
            <PrivateRoute exact path={Routes.Dashboard} component={Dashboard} />
            <PrivateRoute exact path={Routes.MonthlySales} component={SalesByMonth} />
            <PrivateRoute exact path={Routes.Stock} component={StockControl} />
            <PrivateRoute exact path={Routes.Comparisons} component={Comparison} />
            <PrivateRoute exact path={Routes.Expenses} component={Expenses} />
            <PrivateRoute
              exact
              path={Routes.ExpensesCategories}
              component={ExpensesCategories}
            />
            <PrivateRoute exact path={Routes.SalesLocations} component={SalesLocations} />
            <PrivateRoute
              exact
              path={Routes.OrdersLocations}
              component={OrdersLocations}
            />
            <PrivateRoute exact path={Routes.GeneralReport} component={GeneralReport} />
            <PrivateRoute
              exact
              path={Routes.TransactionsReport}
              component={TransactionReport}
            />
            <PrivateRoute
              exact
              path={Routes.Certification}
              component={CertificationPage}
            />
            <PrivateRoute exact path={Routes.PaymentsReport} component={PaymentsReport} />
            <PrivateRoute exact path={Routes.StockReport} component={StockReport} />
            <PrivateRoute exact path={Routes.ProductsSold} component={ProductSold} />
            <PrivateRoute
              exact
              path={Routes.SalesByCategorySummary}
              component={SalesByCategoryReport}
            />
          </BrowserRouter>
        </ThemeProvider>
      </BussinesPulseContextProvider>
    </SnackbarProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
