/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ExpensesCrudTable } from '../../components';
import { useInitializeExpenses } from '../../hooks';
import { SxStyleProp } from 'theme-ui';

//Component Styles
const expensesContainer: SxStyleProp = {
  minHeight: '30rem',
  width: ['100%', '100%', '60rem'],
  padding: ['.5rem', '2rem'],
  margin: '0 auto'
};
const expensesH1: SxStyleProp = {
  textAlign: 'center',
  mt: 0
};

export function Expenses() {
  useInitializeExpenses();
  return (
    <div sx={expensesContainer}>
      <h1 sx={expensesH1}>Gestion de costos</h1>
      <ExpensesCrudTable />
    </div>
  );
}
