import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { SalesByCategoryRecord, StatsApi } from '../../api';

export interface SalesByCategorySummary {
  salesData: SalesByCategoryRecord[];
  totalBase: number;
  totalUnits: number;
  totalAmount: number;
  grandTotal: number;
}

export function useSalesByCategory(fromDate: string, toDate: string, pos: string) {
  const [salesSummary, setSetSalesSummary] = useState<SalesByCategorySummary>();
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const getSales = async () => {
    try {
      setIsLoading(true);
      const salesData = await StatsApi.getSalesByCategorySummary(
        fromDate,
        toDate,
        pos as string
      );
      let totalBase = 0,
        totalUnits = 0,
        totalAmount = 0,
        grandTotal = 0;

      salesData.forEach(s => {
        totalAmount += s.amount;
        totalUnits += s.units;
        totalBase += s.base;
        grandTotal += s.total;
      });

      setSetSalesSummary({ salesData, totalAmount, totalUnits, totalBase, grandTotal });
    } catch (error) {
      enqueueSnackbar('Algo salio mal 😢', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    salesSummary,
    getSales,
    isLoading,
    reinitialize: () => setSetSalesSummary(undefined)
  };
}
