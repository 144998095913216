/** @jsx jsx */
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Container
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import { LineGraph, PosSelect, CustomSnackBar } from '../../components';
import { formatTotalSalesForComparisonGraph } from '../../utils';
import { Pos } from '../../models';
import { PeriodsComparison, StatsApi } from '../../api';
import * as css from './comparisonCss';

const posMap = {
  [Pos.Belgrano]: Pos.Belgrano,
  [Pos.Bicentenario]: Pos.Bicentenario,
  [Pos.GrandBourg]: Pos.GrandBourg,
  [Pos.Freezados]: Pos.Freezados,
  [Pos.Factory]: Pos.Factory,
  [Pos.PaseoSalta]: Pos.PaseoSalta,
  [Pos.ZonaSur]: Pos.ZonaSur,
  [Pos.Todos]: Pos.Todos
};
export type ComparisonPeriods = 'month' | 'week';
export function Comparison() {
  const [error, setError] = useState<string>('');
  console.log(new Date());
  console.log(new Date().toISOString().substring(0, 10));
  const [selectedDate, setselectedDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [comparisonsData, setComparisonData] = useState<PeriodsComparison[]>([]);
  const [pos, setPos] = useState(Pos.Belgrano);
  const [period, setPeriod] = useState<ComparisonPeriods>('month');
  const showUnits = false;

  async function getStats() {
    try {
      setIsLoading(true);
      const comparisons = await StatsApi.getPeriodComparison(selectedDate, period);
      setIsLoading(false);
      setComparisonData(comparisons);
    } catch (error) {
      setError('Hubo un error insesperado, por favor intenta nuevamente mas tarde.');
    }
  }

  const handleDateChange = (handler: Function) => (date: MaterialUiPickersDate) => {
    if (date) {
      try {
        let newDate = date.toISOString().substring(0, 10);
        handler(newDate);
      } catch (error) {
        return;
      }
    }
  };

  const linesToShow = comparisonsData
    ? comparisonsData.filter(c => c.pos === posMap[pos])
    : [];

  const hanldePeriodChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setPeriod(e.target.value as ComparisonPeriods);
    setComparisonData([]);
  };

  return (
    <Container sx={css.comparisonContainer}>
      <h2 sx={css.comparisonH2}>Comparaciones de ventas semanales / mensuales</h2>
      <div sx={css.comparisonFlex}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Fecha de referencia:"
            value={selectedDate}
            onChange={handleDateChange(setselectedDate)}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
        <div>
          <FormControl sx={css.comparisonFormControl}>
            <InputLabel id="label">Periodo</InputLabel>
            <Select id="demo-simple-select" value={period} onChange={hanldePeriodChange}>
              <MenuItem value="month">Un Mes</MenuItem>
              <MenuItem value="week">Una Semana</MenuItem>
            </Select>
          </FormControl>

          <Button
            onClick={getStats}
            sx={css.comparisonButton}
            color="primary"
            variant="contained"
          >
            CONSULTAR
          </Button>
        </div>
      </div>
      <Grid container>
        {!!linesToShow.length && <PosSelect onSelect={s => setPos(s)} value={pos} />}
        {isLoading && <LinearProgress sx={css.comparisonLoading} />}
      </Grid>
      {error && (
        <CustomSnackBar
          message={error}
          variant="error"
          verticalAlignment="top"
          open={!!error}
          horizontalAlignment="center"
          onClose={() => setError('')}
        />
      )}
      <Grid container spacing={2}>
        {!!comparisonsData.length && (
          <LineGraph
            data={
              linesToShow ? formatTotalSalesForComparisonGraph(linesToShow, period) : []
            }
            yFormat={value => (showUnits ? parseInt(value.toString(), 10) : `$${value}`)}
            isMoney={!showUnits}
            // so for some reason when it renders a second time, linespacing wouldnt update properly,
            // so checking here that when comparisonData is present we set a higher number than when the graph renders the first time
            lineSpacing={period === 'month' ? 30 : 60}
            hideTotalInTooltip
          />
        )}
      </Grid>
    </Container>
  );
}
