import { getAuthToken } from '../utils';
import { Routes } from '../const';

export async function fetchWithAuth(input: RequestInfo, init: RequestInit) {
  init.headers = Object.assign({}, init.headers, { Authorization: getAuthToken() });
  const response = await fetch(input, init);
  if (response.status === 401) window.location.href = Routes.Login;
  return response;
}

export const middleware = { fetch: fetchWithAuth };
