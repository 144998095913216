/**@jsx jsx */
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ChangeEvent } from 'react';
import { jsx } from 'theme-ui';

interface ComboboxProps<T> {
  name?: string;
  options: T[];
  getOptionLabel: (c: T) => string;
  onChange: (e: ChangeEvent<{}>, v: T | null) => void;
  defaultValue?: T;
  value?: T | '';
  placeholder?: string;
  fullWidth?: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  noOptionsText?: string;
  className?: string;
  loading?: boolean;
}

export function Combobox<T>(props: ComboboxProps<T>) {
  const {
    onChange,
    options,
    defaultValue,
    value,
    getOptionLabel,
    error,
    helperText,
    label,
    placeholder,
    noOptionsText,
    fullWidth,
    className,
    loading
  } = props;

  const properValue: T | null | undefined = value === '' ? null : value;

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      defaultValue={defaultValue}
      value={properValue}
      className={className}
      onChange={(e, v) => onChange(e, v)}
      fullWidth={fullWidth}
      loading={loading}
      noOptionsText={noOptionsText}
      placeholder={placeholder}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          variant="outlined"
        />
      )}
    />
  );
}
