/**@jsx jsx */
import { Fragment } from 'react';
import { jsx } from 'theme-ui';
import { IExpenseDTO } from '../../../api';
import { useModal } from '../../../hooks';
import { CrudTableRowActions, ExpensesForm } from '../../../components';
import { useExpenseUpdate } from './useExpensesUpdate';

interface ExpensesRowActionsProps {
  selectedExpense: IExpenseDTO;
}

export function ExpensesRowActions(props: ExpensesRowActionsProps) {
  const { selectedExpense } = props;
  const { Modal, openModal, closeModal } = useModal();
  const { updateState } = useExpenseUpdate();
  const handleSubmit = (exp: IExpenseDTO) => {
    updateState(exp);
    closeModal();
  };
  return (
    <Fragment>
      <Modal title="Editar Costo">
        <ExpensesForm selectedExpense={selectedExpense} onSubmit={handleSubmit} />
      </Modal>
      <CrudTableRowActions
        hideDelete
        onEditClick={openModal}
        onRemoveClick={() => null}
      />
    </Fragment>
  );
}
