export enum Roles {
  Client = 'Client',
  Marketing = 'Marketing',
  StockManager = 'StockManager',
  BranchManager = 'BranchManager',
  BranchSupervisor = 'BranchSupervisor',
  ExpensesDataEntry = 'ExpensesDataEntry',
  SuperAdmin = 'SuperAdmin',
  Delivery = 'Delivery'
}
