import React, { useState } from 'react';
import { SalesByDay, StatsApi } from '../../api';
import { PosCategories } from '../../const';
import { Pos, ProductGraphBar } from '../../models';
import { formatDataForBarChart } from './salesUtils';

export function useSales(fromDate: string, toDate: string) {
  const [error, setError] = React.useState<string>('');
  const [isLoadingTotals, setIsLoadingTotals] = useState<boolean>(false);

  const [totalSales, setTotalSales] = useState<SalesByDay[]>([]);
  const [totalUnits, setTotalUnits] = useState<SalesByDay[]>([]);
  const [productsSold, setProductsSold] = useState<ProductGraphBar[]>([]);

  const handleQueryClick = async () => {
    setIsLoadingTotals(true);
    try {
      const bicentenarioProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.Bicentenario
      );
      const bicentenarioSalesPromise = StatsApi.getSalesByDay(
        fromDate,
        toDate,
        Pos.Bicentenario
      );
      const bicentenarioTotalsPromise = StatsApi.getUnitsSoldByDay(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.Bicentenario
      );

      const belgranoSalesPromise = StatsApi.getSalesByDay(fromDate, toDate, Pos.Belgrano);
      const belgranoTotalsPromise = StatsApi.getUnitsSoldByDay(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.Belgrano
      );
      const belgranoProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.Belgrano
      );

      const gbSalesPromise = StatsApi.getSalesByDay(fromDate, toDate, Pos.GrandBourg);
      const gbTotalsPromise = StatsApi.getUnitsSoldByDay(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.GrandBourg
      );
      const gbProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.GrandBourg
      );

      const sanLorenzoSalesPromise = StatsApi.getSalesByDay(
        fromDate,
        toDate,
        Pos.Freezados
      );
      const sanLorenzoTotalsPromise = StatsApi.getUnitsSoldByDay(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.Freezados
      );
      const sanLorenzoProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.Freezados
      );

      const paseoSaltaSalesPromise = StatsApi.getSalesByDay(
        fromDate,
        toDate,
        Pos.PaseoSalta
      );
      const paseoSaltaTotalsPromise = StatsApi.getUnitsSoldByDay(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.PaseoSalta
      );
      const paseoSaltaProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.PaseoSalta
      );

      const zonaSurSalesPromise = StatsApi.getSalesByDay(fromDate, toDate, Pos.ZonaSur);
      const zonaSurTotalsPromise = StatsApi.getUnitsSoldByDay(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.ZonaSur
      );
      const zonaSurProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        PosCategories.EMPANADAS,
        Pos.ZonaSur
      );

      const [
        belgranoProductsSold,
        bicentenarioProductsSold,
        gbProductsSold,
        sanLorenzoProductsSold,
        paseoSaltaProductsSold,
        zonaSurProductsSold
      ] = await Promise.all([
        belgranoProductsSoldPromise,
        bicentenarioProductsSoldPromise,
        gbProductsSoldPromise,
        sanLorenzoProductsSoldPromise,
        paseoSaltaProductsSoldPromise,
        zonaSurProductsSoldPromise
      ]);

      setProductsSold(
        formatDataForBarChart(
          belgranoProductsSold,
          bicentenarioProductsSold,
          gbProductsSold,
          sanLorenzoProductsSold,
          paseoSaltaProductsSold,
          zonaSurProductsSold
        )
      );

      const [
        belgranoSales,
        belgranoTotals,
        bicentenarioSales,
        bicentenarioTotals,
        gbSales,
        gbTotals,
        sanLorenzoSales,
        sanLorenzoTotals,
        paseoSaltaSales,
        paseoSaltaTotals,
        zonaSurSales,
        zonaSurTotals
      ] = await Promise.all([
        belgranoSalesPromise,
        belgranoTotalsPromise,
        bicentenarioSalesPromise,
        bicentenarioTotalsPromise,
        gbSalesPromise,
        gbTotalsPromise,
        sanLorenzoSalesPromise,
        sanLorenzoTotalsPromise,
        paseoSaltaSalesPromise,
        paseoSaltaTotalsPromise,
        zonaSurSalesPromise,
        zonaSurTotalsPromise
      ]);

      setTotalSales([
        belgranoSales,
        bicentenarioSales,
        gbSales,
        sanLorenzoSales,
        paseoSaltaSales,
        zonaSurSales
      ]);
      setTotalUnits([
        belgranoTotals,
        bicentenarioTotals,
        gbTotals,
        sanLorenzoTotals,
        paseoSaltaTotals,
        zonaSurTotals
      ]);
      setIsLoadingTotals(false);
    } catch (error) {
      console.error(error);
      setError('Ups! Algo salio mal. \n Volve a intentar en unos instantes');
      setIsLoadingTotals(false);
    }
  };
  return {
    handleQueryClick,
    error,
    setError,
    isLoadingTotals,
    totalUnits,
    totalSales,
    productsSold
  };
}


