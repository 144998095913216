/** @jsx jsx */
import { Button, CircularProgress, Container, Grid, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { Flex, jsx } from 'theme-ui';
import { CustomSnackBar, Filters, PosSelect } from '../../components';
import { LocationsMap } from '../../components/map';
import { Pos } from '../../models';
import { formatLocation } from '../../utils';
import * as css from './salesLocationsCss';
import { useSalesLocations } from './useSalesLocations';

export function SalesLocations() {
  let initalDate = new Date();
  initalDate.setDate(initalDate.getDate() - 7);
  const [selectedFromDate, setSelectedFromDate] = useState<Date>(initalDate);
  const [selectedToDate, setSelectedToDate] = useState<Date>(new Date());
  const fromDate = selectedFromDate.toISOString().substring(0, 10);
  const toDate = selectedToDate.toISOString().substring(0, 10);

  const [pos, setPos] = useState(Pos.Todos);

  const {
    handleQueryAddressesClick,
    isLoading,
    hasClicked,
    addressesToshow,
    error,
    setError
  } = useSalesLocations(pos, fromDate, toDate);

  return (
    <React.Fragment>
      {error && (
        <CustomSnackBar
          message={error}
          variant="error"
          verticalAlignment="top"
          open={!!error}
          horizontalAlignment="center"
          onClose={() => setError('')}
        />
      )}
      <Container sx={css.salesLocationContainer}>
        <Flex sx={css.salesLocationContainerFlex}>
          <Filters
            selectedFromDate={selectedFromDate}
            setSelectedToDate={setSelectedToDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
          />
          <Grid item lg={2}>
            <Button
              onClick={handleQueryAddressesClick}
              sx={css.salesLocationButton}
              color="primary"
              variant="contained"
            >
              CONSULTAR VENTAS
            </Button>
          </Grid>
        </Flex>
        <Grid item xs={12}>
          <Paper sx={css.graphWrapper}>
            <h2 sx={css.graphTitle}>Ubicaciones de Ventas</h2>
            <div sx={css.selectorContainer}>
              <PosSelect value={pos} onSelect={p => setPos(p)} showAll />
            </div>
            {isLoading && <CircularProgress sx={css.loader} color="primary" />}

            {hasClicked && (
              <LocationsMap
                loading={isLoading}
                points={addressesToshow.map(a =>
                  Object.assign(a, formatLocation(a.location))
                )}
                showPos
              />
            )}
          </Paper>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
