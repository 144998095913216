/** @jsx jsx */
import {
    Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Fragment } from 'react';
import { Box, jsx } from 'theme-ui';

interface CertificationSummaryProps {
  totalSales?: number;
  totalCosts?: number;
}
export function CertificationSummary(props: CertificationSummaryProps) {
  const { totalCosts, totalSales } = props;
  const fee = !totalSales ? 0 : (totalSales / 100) * 6;
  return (
    <Fragment>
      {!!totalSales && !!totalCosts && (
        <Box sx={{ width: ['90vw', '60vw', '30vw'], mx: 'auto' }} id="certificationSummary">
          <TableContainer component={Paper} sx={{ maxHeight: '70vh', width: '100%' }}>
            <Table
              stickyHeader
              aria-label="collapsible table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <b>Resumen</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell><b>Ingresos</b></TableCell>
                  <TableCell align="center">${totalSales}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>Egresos</b></TableCell>
                  <TableCell align="center">
                    ${totalCosts} ({((totalCosts / totalSales) * 100).toFixed(2)} %)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>Regalias</b></TableCell>
                  <TableCell align="center">$ {fee.toFixed(2)} (6%)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>Bruto</b></TableCell>
                  <TableCell align="center" sx={{color: totalSales - totalCosts > 0 ? 'green' : 'red'}}>
                    ${totalSales - totalCosts} (
                    {(((totalSales - totalCosts) / totalSales) * 100).toFixed(2)} %)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>Neto</b></TableCell>
                  <TableCell align="center" sx={{color: totalSales - totalCosts - fee > 0 ? 'green' : 'red'}}>
                    ${(totalSales - totalCosts - fee).toFixed(2)} (
                    {(((totalSales - totalCosts - fee) / totalSales) * 100).toFixed(2)} %)
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Fragment>
  );
}
