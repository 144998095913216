/** @jsx jsx */
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { jsx } from 'theme-ui';
import { useExpensesState } from '../../../contexts';
import { AddCategoryAction } from './addCategoryAction';
import { RowActions } from './rowActions';
import * as css from './expensesCategoriesTableCss';

export function ExpensesCategoriesTable() {
  const [expState] = useExpensesState();
  const { values: categories, isLoading: loadingCategories } = expState.categories;
  return (
    <div>
      <AddCategoryAction />
      <Table size="small" sx={css.table}>
        <TableHead>
          <TableRow>
            <TableCell sx={css.cheaderCell}>Categoria</TableCell>
            <TableCell sx={css.cheaderCell}>Descripcion</TableCell>
            <TableCell sx={css.cheaderCell}>Categoria Madre</TableCell>
            <TableCell sx={css.cheaderCell}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((categ, i) => {
            return (
              <TableRow key={categ.id} hover>
                <TableCell padding="none" component="th" scope="row">
                  {categ.name}
                </TableCell>
                <TableCell padding="none" sx={css.secondCell} title={categ.description}>
                  {categ.description ?? '-'}
                </TableCell>
                <TableCell padding="none" sx={css.bodyCell} align="right">
                  {categ.category?.name ?? '-'}
                </TableCell>
                <TableCell padding="none" sx={css.centerAlign} align="right">
                  <RowActions selectedCategory={categ} />
                </TableCell>
              </TableRow>
            );
          })}
          {!categories.length && (
            <TableRow>
              <TableCell colSpan={4}>
                <p sx={css.centerAlign}>
                  {' '}
                  {loadingCategories
                    ? 'Cargando...'
                    : 'No hay categorias para mostrar'}{' '}
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
