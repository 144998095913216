/** @jsx jsx */
import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Flex, jsx } from 'theme-ui';
import { ExpenseDTO, ExpensesApi, IBranchDTO } from '../../api';
import { Combobox, Filters } from '../../components';
import { useExpensesState } from '../../contexts';
import { useInitializeExpenses } from '../../hooks';
import { posIdToEnumMap } from '../../models';
import { formatDate, getBeginingOfTheMonth } from '../../utils';
import { SalesByCategoryTable } from '../salesByCategory/salesByCategoryTable';
import { useSalesByCategory } from '../salesByCategory/useSalesByCategory';
import { CertificationSummary } from './certificationSummary';
import * as css from './salesCss';

export function CertificationPage() {
  const [selectedFromDate, setSelectedFromDate] = useState<Date>(getBeginingOfTheMonth());
  const [selectedToDate, setSelectedToDate] = useState<Date>(new Date());
  const [expenses, setExpenses] = useState<ExpenseDTO[]>([]);
  const [totalCosts, setTotals] = useState(0);

  const [branchId, setBranchId] = React.useState<number | null>(null);
  const [expensesLoading, setExpLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const getExpenses = async () => {
    try {
      setExpLoading(true);
      const exps = await ExpensesApi.getExpensesGrouped(
        null,
        branchId,
        selectedFromDate,
        selectedToDate,
        0,
        1000000
      );
      setExpenses(exps?.results || []);
      setTotals(exps.totalAmount || 0);
    } catch (error) {
      enqueueSnackbar('Algo salio mal!', { variant: 'error' });
    } finally {
      setExpLoading(false);
    }
  };

  useInitializeExpenses();
  const [expState] = useExpensesState();
  const pos =
    posIdToEnumMap[expState.branches.values.find(v => v.id === branchId)?.id || 1];
  const { salesSummary, getSales, reinitialize } = useSalesByCategory(
    selectedFromDate.toISOString().substring(0, 10),
    selectedToDate.toISOString().substring(0, 10),
    pos
  );

  const handleQuery = () => {
    getExpenses();
    getSales();
  };

  const print = () => {
    const mywindow = window.open('', 'PRINT', 'height=800,width=1200')!;

    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById('print')!.innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.print();
    mywindow.close();

    return true;
  }

  return (
    <React.Fragment>
      <Container sx={css.transactionsContainer}>
        <Flex sx={css.transactionsContainerFlex}>
          <Filters
            selectedFromDate={selectedFromDate}
            setSelectedToDate={setSelectedToDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
          />
          <div sx={{ ml: [0, '1rem'], mt: ['1rem', 0] }}>
            <Combobox
              name="branchId"
              options={expState.branches.values}
              getOptionLabel={(c: IBranchDTO) => c?.name ?? ''}
              onChange={(e, v) => {
                reinitialize();
                setExpenses([]);
                setTotals(0);
                setBranchId(v?.id || null);
              }}
              sx={{ width: ['15rem'] }}
              value={expState.branches.values.find(v => v.id === branchId) ?? ''}
              placeholder="Seleccionar sucursal"
              label="Sucursal"
              noOptionsText="No hay sucursales"
              loading={expState.branches.isLoading}
            />
          </div>
          <Grid item lg={2}>
            <Button
              onClick={handleQuery}
              sx={css.actionButton}
              color="primary"
              variant="contained"
            >
              CONSULTAR
            </Button>
            {!!expenses.length && (
              <Button
                onClick={print}
                sx={{ ml: '2rem' }}
                color="primary"
                variant="contained"
              >
                IMPRIMIR
              </Button>
            )}
          </Grid>
        </Flex>

        <Box id="print">
          <style
            dangerouslySetInnerHTML={{
              __html: `@media print {
                @page { size: auto;  margin: 0mm; }

                table {
                  border-collapse: collapse;
                }
                td, th {
                  border: 1px solid grey;
                  font-size: 10px;
                  border-spacing: 0;
                  padding: 2px;
                }
                h1{
                  text-align: center;
                  font-size: 15px;
                  margin-top: 2rem;
                }
                th {
                  background-color: light-grey;
                }
                h2 {
                  font-size: 15px;
                }
                #tablesWrapper {
                  display: flex;
                  justify-content: space-evenly;
                }
                #certificationSummary {
                  margin-left: auto;
                  margin-rigth: auto;
                  width: 30rem;
                }
              }`
            }}
          />
          <Box component="h1" id="title" sx={{ height: 0, opacity: 0 }}>
            Certificacion para {pos.replace('_', ' ')} - {formatDate(selectedFromDate)} al{' '}
            {formatDate(selectedToDate)}
          </Box>

          <CertificationSummary
            totalSales={salesSummary?.grandTotal}
            totalCosts={totalCosts}
          />

          <Grid container>
            {expensesLoading && <LinearProgress sx={css.progressBar} />}
          </Grid>

          {!!expenses.length && (
            <Box
              id="tablesWrapper"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                pt: '1rem',
                width: '90vw',
                mx: 'auto'
              }}
            >
              <Box sx={{ width: ['90vw', '90vw', '40vw'] }}>
                <Box component="h2" sx={{ py: '1rem', fontSize: '1.5rem' }}>
                  Costos de {pos}: <b>${totalCosts}</b>
                </Box>
                <TableContainer
                  component={Paper}
                  sx={{ maxHeight: '70vh', width: '100%' }}
                >
                  <Table
                    stickyHeader
                    aria-label="collapsible table"
                    size="small"
                    sx={css.tableCss}
                  >
                    <TableHead>
                      <TableRow sx={css.tableBorderCss}>
                        <TableCell align="left">
                          <b>Categoria</b>
                        </TableCell>
                        <TableCell sx={css.tableHeaderCss} align="right">
                          <b>Total</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenses.map((t, i) => {
                        return (
                          <TableRow>
                            <TableCell>{t.category.name}</TableCell>
                            <TableCell align="center">$ {t.amount}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ width: ['90vw', '90vw', '40vw'] }}>
                {!!salesSummary && (
                  <SalesByCategoryTable salesSummary={salesSummary} pos={pos} />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
}
