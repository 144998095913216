/**@jsx jsx */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import * as React from 'react';
import { jsx, SxStyleProp } from 'theme-ui';
import { ProductsSold } from '../../../../api';
import { Pos } from '../../../../models';
import { colors } from '../../../../utils';
import { formatProductsSoldForTable } from './productSoldUtils';

interface ProductsSoldTableProps {
  data: ProductsSold[];
  showRevenue?: boolean;
}

type SortBy = {
  column: Pos;
  desc: boolean;
};

//Component Styles
const tableHeaderBlack: SxStyleProp = {
  fontWeight: 'bold',
  color: 'black'
};

const tableValue = (color: string): SxStyleProp => ({
  color: color
});

const tableHeader = (color: string): SxStyleProp => ({
  fontWeight: 'bold',
  color: color
});

export function ProductsSoldTable(props: ProductsSoldTableProps) {
  const { data, showRevenue } = props;

  const [orderBy, setOrderBy] = React.useState<SortBy>({
    column: Pos.Todos,
    desc: true
  });

  const [info, setInfo] = React.useState(formatProductsSoldForTable(data, !!showRevenue));

  React.useEffect(() => {
    setInfo(formatProductsSoldForTable(data, !!showRevenue));
    console.log('running effect bitches');
  }, [showRevenue]);

  const orderData = (column: Pos) => () => {
    const newData = Array.from(info);
    newData.sort((a, b) =>
      orderBy.desc ? a[column]! - b[column]! : b[column]! - a[column]!
    );
    setInfo(newData);
    setOrderBy({ column, desc: !orderBy.desc });
  };
  if (info.length) {
    let belgranoTotal = 0,
      bicentenarioTotal = 0,
      gbTotal = 0,
      freezadosTotal = 0,
      paseoSaltaTotal = 0,
      zonaSurTotal = 0,
      allTotal = 0;
    info.forEach(i => {
      belgranoTotal += i[Pos.Belgrano] || 0;
      bicentenarioTotal += i[Pos.Bicentenario] || 0;
      gbTotal += i[Pos.GrandBourg] || 0;
      freezadosTotal += i[Pos.Freezados] || 0;
      paseoSaltaTotal += i[Pos.PaseoSalta] || 0;
      zonaSurTotal += i[Pos.ZonaSur] || 0;
      allTotal += i[Pos.Todos] || 0;
    });
    console.log('info is', info);
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={tableHeaderBlack}>Pos</TableCell>
            <TableCell sx={tableHeaderBlack}>Variedad</TableCell>
            <TableCell align="right" sx={tableHeader(colors.BELGRANO)}>
              <TableSortLabel
                active={orderBy.column === Pos.Belgrano}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData(Pos.Belgrano)}
              >
                Belgrano
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeader(colors.BICENTENARIO)}>
              <TableSortLabel
                active={orderBy.column === Pos.Bicentenario}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData(Pos.Bicentenario)}
              >
                Bicentenario
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeader(colors.GRANDBOURG)}>
              <TableSortLabel
                active={orderBy.column === Pos.GrandBourg}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData(Pos.GrandBourg)}
              >
                Grand Bourg
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeader(colors.PASEO_SALTA)}>
              <TableSortLabel
                active={orderBy.column === Pos.PaseoSalta}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData(Pos.PaseoSalta)}
              >
                Paseo Salta
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeader(colors.ZONA_SUR)}>
              <TableSortLabel
                active={orderBy.column === Pos.ZonaSur}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData(Pos.ZonaSur)}
              >
                Zona Sur
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeader(colors.FREEZADOS)}>
              <TableSortLabel
                active={orderBy.column === Pos.Freezados}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData(Pos.Freezados)}
              >
                Freezados
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeaderBlack}>
              <TableSortLabel
                active={orderBy.column === Pos.Todos}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData(Pos.Todos)}
              >
                Total
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {info.map((row, i) => (
            <TableRow key={row.reference} hover>
              <TableCell component="th" scope="row">
                {i + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.BELGRANO)}>
                {showRevenue ? '$' : ''}
                {row[Pos.Belgrano] || '-'}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.BICENTENARIO)}>
                {showRevenue ? '$' : ''}
                {row[Pos.Bicentenario] || '-'}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.GRANDBOURG)}>
                {showRevenue ? '$' : ''}
                {row[Pos.GrandBourg] || '-'}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.PASEO_SALTA)}>
                {showRevenue ? '$' : ''}
                {row[Pos.PaseoSalta] || '-'}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.ZONA_SUR)}>
                {showRevenue ? '$' : ''}
                {row[Pos.ZonaSur] || '-'}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.FREEZADOS)}>
                {showRevenue ? '$' : ''}
                {row[Pos.Freezados] || '-'}
              </TableCell>
              <TableCell align="right">
                {showRevenue ? '$' : ''}
                {row[Pos.Todos]}
              </TableCell>
            </TableRow>
          ))}
          <TableRow hover>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row">
              TOTALES
            </TableCell>
            <TableCell align="right">
              {showRevenue ? '$' : ''}
              {belgranoTotal}
            </TableCell>
            <TableCell align="right">
              {showRevenue ? '$' : ''}
              {bicentenarioTotal}
            </TableCell>
            <TableCell align="right">
              {showRevenue ? '$' : ''}
              {gbTotal}
            </TableCell>
            <TableCell align="right">
              {showRevenue ? '$' : ''}
              {paseoSaltaTotal}
            </TableCell>
            <TableCell align="right">
              {showRevenue ? '$' : ''}
              {zonaSurTotal}
            </TableCell>
            <TableCell align="right">
              {showRevenue ? '$' : ''}
              {freezadosTotal}
            </TableCell>
            <TableCell align="right">
              {showRevenue ? '$' : ''}
              {allTotal}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
  return null;
}
