import { ProductsSold } from '../../api';
import { ProductGraphBar } from '../../models';
import { colors } from '../../utils';

export const formatDataForBarChart = (
  salesBlengrano: ProductsSold,
  salesBicentenario: ProductsSold,
  salesGrandBourg: ProductsSold,
  salesSanLorenzo: ProductsSold,
  salesPaseoSalta: ProductsSold,
  salesZonaSur: ProductsSold
): ProductGraphBar[] => {
  let bars: ProductGraphBar[] = [];

  salesBicentenario?.sales?.map(s => {
    bars.push({
      bicentenario: s.units,
      name: s?.name?.replace('1 ', '') as string,
      reference: s?.reference?.replace('1-', '') as string,
      grandBourg: 0,
      sanLorenzo: 0,
      sanLorenzoColor: colors.FREEZADOS,
      grandBourgColor: colors.GRANDBOURG,
      belgranoColor: colors.BELGRANO,
      bicentenarioColor: colors.BICENTENARIO,
      paseoSalta: 0,
      paseoSaltaColor: colors.PASEO_SALTA,
      zonaSur: 0,
      zonaSurColor: colors.ZONA_SUR,
      belgrano: 0,
      total: s.units
    });
  });

  salesBlengrano?.sales?.map(s => {
    let bar = bars.find(
      b => b.reference === s?.reference?.replace('1-', '')
    ) as ProductGraphBar;
    if (!bar) {
      bars.push({
        bicentenario: 0,
        grandBourg: 0,
        grandBourgColor: colors.GRANDBOURG,
        sanLorenzo: 0,
        sanLorenzoColor: colors.FREEZADOS,
        name: s?.name?.replace('1 ', '') as string,
        reference: s?.reference?.replace('1-', '') as string,
        belgranoColor: colors.BELGRANO,
        bicentenarioColor: colors.BICENTENARIO,
        paseoSalta: 0,
        paseoSaltaColor: colors.PASEO_SALTA,
        belgrano: s.units,
        zonaSur: 0,
        zonaSurColor: colors.ZONA_SUR,
        total: s.units
      });
    } else {
      bar.belgrano = s.units;
      bar.total = bar.total + s.units;
    }
  });

  salesSanLorenzo?.sales?.map(s => {
    let bar = bars.find(
      b => b.reference === s?.reference?.replace('1-', '')
    ) as ProductGraphBar;
    if (!bar) {
      bars.push({
        bicentenario: 0,
        grandBourg: 0,
        sanLorenzo: s.units,
        sanLorenzoColor: colors.FREEZADOS,
        grandBourgColor: colors.GRANDBOURG,
        name: s?.name?.replace('1 ', '') as string,
        reference: s?.reference?.replace('1-', '') as string,
        belgranoColor: colors.BELGRANO,
        bicentenarioColor: colors.BICENTENARIO,
        paseoSalta: 0,
        paseoSaltaColor: colors.PASEO_SALTA,
        belgrano: 0,
        zonaSur: 0,
        zonaSurColor: colors.ZONA_SUR,
        total: s.units
      });
    } else {
      bar.sanLorenzo = s.units;
      bar.total = bar.total + s.units;
    }
  });

  salesGrandBourg?.sales?.map(s => {
    let bar = bars.find(
      b => b.reference === s?.reference?.replace('1-', '')
    ) as ProductGraphBar;
    if (!bar) {
      bars.push({
        bicentenario: 0,
        grandBourg: s.units,
        grandBourgColor: colors.GRANDBOURG,
        sanLorenzo: 0,
        sanLorenzoColor: colors.FREEZADOS,
        name: s?.name?.replace('1 ', '') as string,
        reference: s?.reference?.replace('1-', '') as string,
        belgranoColor: colors.BELGRANO,
        bicentenarioColor: colors.BICENTENARIO,
        paseoSalta: 0,
        paseoSaltaColor: colors.PASEO_SALTA,
        belgrano: 0,
        zonaSur: 0,
        zonaSurColor: colors.ZONA_SUR,
        total: s.units
      });
    } else {
      bar.grandBourg = s.units;
      bar.total = bar.total + s.units;
    }
  });

  salesPaseoSalta?.sales?.map(s => {
    let bar = bars.find(
      b => b.reference === s?.reference?.replace('1-', '')
    ) as ProductGraphBar;
    if (!bar) {
      bars.push({
        bicentenario: 0,
        grandBourg: 0,
        grandBourgColor: colors.GRANDBOURG,
        sanLorenzo: 0,
        sanLorenzoColor: colors.FREEZADOS,
        name: s?.name?.replace('1 ', '') as string,
        reference: s?.reference?.replace('1-', '') as string,
        belgranoColor: colors.BELGRANO,
        bicentenarioColor: colors.BICENTENARIO,
        paseoSalta: s.units,
        paseoSaltaColor: colors.PASEO_SALTA,
        belgrano: 0,
        zonaSur: 0,
        zonaSurColor: colors.ZONA_SUR,
        total: s.units
      });
    } else {
      bar.paseoSalta = s.units;
      bar.total = bar.total + s.units;
    }
  });

  salesZonaSur?.sales?.map(s => {
    let bar = bars.find(
      b => b.reference === s?.reference?.replace('1-', '')
    ) as ProductGraphBar;
    if (!bar) {
      bars.push({
        bicentenario: 0,
        grandBourg: 0,
        grandBourgColor: colors.GRANDBOURG,
        sanLorenzo: 0,
        sanLorenzoColor: colors.FREEZADOS,
        name: s?.name?.replace('1 ', '') as string,
        reference: s?.reference?.replace('1-', '') as string,
        belgranoColor: colors.BELGRANO,
        bicentenarioColor: colors.BICENTENARIO,
        paseoSalta: 0,
        paseoSaltaColor: colors.PASEO_SALTA,
        belgrano: 0,
        zonaSur: s.units,
        zonaSurColor: colors.ZONA_SUR,
        total: s.units
      });
    } else {
      bar.zonaSur = s.units;
      bar.total = bar.total + s.units;
    }
  });

  return bars;
};
