/** @jsx jsx */
import { Container, Grid } from '@material-ui/core';
import { jsx } from 'theme-ui';
import { SalesByMonthGraphSection } from '../../components';
import { SxStyleProp } from 'theme-ui';

//Component Styles
const salesByMonthContainer: SxStyleProp = {
  paddingTop: '1rem',
  paddingBottom: '2rem',
  maxWidth: '100vw'
};
//

export function SalesByMonth() {
  return (
    <Container sx={salesByMonthContainer}>
      <Grid container spacing={2}>
        <SalesByMonthGraphSection />
      </Grid>
    </Container>
  );
}
