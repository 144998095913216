import { useState } from 'react';
import { ProductsSold, StatsApi } from '../../api';
import { Pos } from '../../models';

export function useProductsSold(fromDate: string, toDate: string, category: string) {
  const [error, setError] = useState<string>('');
  const [isLoadingUnits, setIsLoadingUnits] = useState<boolean>(false);

  const [productsSold, setProductsSold] = useState<ProductsSold[]>([]);

  const getUnits = async () => {
    setIsLoadingUnits(true);
    try {
      const bicentenarioProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        category,
        Pos.Bicentenario
      );
      const belgranoProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        category,
        Pos.Belgrano
      );
      const gbProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        category,
        Pos.GrandBourg
      );
      const sanLorenzoProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        category,
        Pos.Freezados
      );
      const paseoSaltaProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        category,
        Pos.PaseoSalta
      );
      const zonaSurProductsSoldPromise = StatsApi.getProductsSoldByDates(
        fromDate,
        toDate,
        category,
        Pos.ZonaSur
      );

      const [
        belgranoProductsSold,
        bicentenarioProductsSold,
        gbProductsSold,
        sanLorenzoProductsSold,
        paseoSaltaProductsSold,
        zonaSurProductsSold
      ] = await Promise.all([
        belgranoProductsSoldPromise,
        bicentenarioProductsSoldPromise,
        gbProductsSoldPromise,
        sanLorenzoProductsSoldPromise,
        paseoSaltaProductsSoldPromise,
        zonaSurProductsSoldPromise
      ]);
      setProductsSold([
        belgranoProductsSold,
        bicentenarioProductsSold,
        gbProductsSold,
        sanLorenzoProductsSold,
        paseoSaltaProductsSold,
        zonaSurProductsSold
      ]);
      setIsLoadingUnits(false);
    } catch (error) {
      console.error(error);
      setError('Ups! Algo salio mal. \n Volve a intentar en unos instantes');
      setIsLoadingUnits(false);
    }
  };
  return {
    getUnits,
    error,
    setError,
    isLoadingUnits,
    clear: () => setProductsSold([]),
    productsSold
  };
}
