/** @jsx jsx */
import { useSnackbar } from 'notistack';
import { Fragment } from 'react';
import { jsx } from 'theme-ui';
import { ExpensesCategoryApi, IExpenseCategoryDTO } from '../../../api';
import { useModal } from '../../../hooks';
import { CrudTableRowActions } from '../../crudTableRowActions';
import { ExpensesCategoriesForm } from '../expensesCategoriesForm';
import { useCategoryUpdate } from './useCategoryUpdate';

export function RowActions(props: { selectedCategory: IExpenseCategoryDTO }) {
  const { selectedCategory } = props;
  const { Modal, openModal, closeModal } = useModal();
  const { handleCategoryUpdate, handleCategoryDeletion } = useCategoryUpdate(closeModal);
  const { enqueueSnackbar } = useSnackbar();

  const removeCategory = async () => {
    try {
      await ExpensesCategoryApi.deleteExpenseCategory(selectedCategory.id);
      handleCategoryDeletion(selectedCategory);
      enqueueSnackbar('Categoria eliminada con exito', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(
        'Algo salio mal y no se pudo completar la operacion, intenta mas tarde.',
        { variant: 'error' }
      );
    }
  };

  return (
    <Fragment>
      <Modal title="Editar Categoria">
        <ExpensesCategoriesForm
          selectedCategory={selectedCategory}
          onSubmit={handleCategoryUpdate}
        />
      </Modal>
      <CrudTableRowActions onEditClick={openModal} onRemoveClick={removeCategory} />
    </Fragment>
  );
}
