/** @jsx jsx */
import { CircularProgress, FormControlLabel, Grid, Switch } from '@material-ui/core';
import React from 'react';
import { jsx } from 'theme-ui';
import { ProductsSold } from '../../../api';
import { graphTitleCss, loaderCss } from '../../../styles';
import { GraphContainer } from '../../graphContainer';
import { ProductsSoldTable } from './productSoldTable';

interface UnitSoldSectionProps {
  productsSold: ProductsSold[];
  isLoading: boolean;
}

export function UnitSoldSection(props: UnitSoldSectionProps) {
  const { productsSold, isLoading } = props;

  const [showRevenue, setShowRevenue] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      {!!productsSold.length && (
        <Grid item xs={12}>
          <GraphContainer>
            <FormControlLabel
              control={
                <Switch
                  checked={showRevenue}
                  onChange={(e, c) => setShowRevenue(c)}
                  color="primary"
                />
              }
              label={`Mostrar ${showRevenue ? 'unidades' : 'ingresos'}`}
            />
            <h2 sx={graphTitleCss}>Variedades vendidas</h2>
            {isLoading && <CircularProgress sx={loaderCss} color="primary" />}
            
              <ProductsSoldTable data={productsSold} showRevenue={showRevenue} />
            
          </GraphContainer>
        </Grid>
      )}
    </React.Fragment>
  );
}
