/** @jsx jsx */
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { Flex, jsx, SxStyleProp } from 'theme-ui';
import { GroupedDataGraphResponse, ReportApi } from '../../api';
import { ComparisonBarGraph, Filters, GraphContainer } from '../../components';
import { loaderCss } from '../../styles';
import { getBeginingOfTheMonth } from '../../utils';
import { HighlightsBalance } from './highlightsBalance';

const filterCss: SxStyleProp = {
  padding: '20px'
};

const highlightsCss: SxStyleProp = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};

export function GeneralReport() {
  const [barGraphData, setBarGraphData] = useState<GroupedDataGraphResponse[]>();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFromDate, setSelectedFromDate] = useState<Date>(getBeginingOfTheMonth());
  const [selectedToDate, setSelectedToDate] = useState<Date>(new Date());
  const fromDate = selectedFromDate.toISOString().substring(0, 10);
  const toDate = selectedToDate.toISOString().substring(0, 10);

  useEffect(() => {
    getExpensesVsIncomeByBranch();
  }, [selectedFromDate, selectedToDate]);

  const getExpensesVsIncomeByBranch = async () => {
    try {
      setIsLoading(true);
      const response = await ReportApi.getBalanceByBranch(
        null,
        new Date(fromDate),
        new Date(toDate)
      );
      if (response) {
        setBarGraphData(response);
      }
    } catch (error) {
      enqueueSnackbar(
        'Algo salio mal y no se pudo completar la operacion, intenta mas tarde.',
        { variant: 'error' }
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <GraphContainer>
        <h3>Reporte General</h3>
        <div sx={filterCss}>
          <Filters
            selectedFromDate={selectedFromDate}
            setSelectedToDate={setSelectedToDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
          />
        </div>
        <Flex sx={highlightsCss}>
          {barGraphData?.length && <HighlightsBalance data={barGraphData} />}
        </Flex>
        <br />
        <br />
        <ComparisonBarGraph
          keys={
            barGraphData?.length && barGraphData[0].yData
              ? barGraphData[0].yData?.map(x => (x.yKey ? x.yKey : ''))
              : []
          }
          data={barGraphData ?? []}
          showLegends={true}
          showNumbersInColumns={false}
        />
      </GraphContainer>
      {isLoading && <CircularProgress sx={loaderCss} color="primary" />}
    </Fragment>
  );
}
