/** @jsx jsx */
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import { jsx } from 'theme-ui';
import logo from '../../assets/logo.png';
import { MenuDrawer } from './menuDrawer';
import * as css from './navMenuCss';

export function NavMenu() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div sx={css.root}>
      <AppBar position="relative" className={clsx(css.appBar, open && css.appBarShift)}>
        <Toolbar sx={css.toolbar}>
          <img src={logo} alt="Logo" sx={css.img} draggable={false} />
          <Typography component="h1" variant="h6" noWrap sx={css.typography}>
            Estadísticas
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && css.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MenuDrawer open={open} handleDrawerClose={handleDrawerClose} />
    </div>
  );
}
