/** @jsx jsx */
import { jsx, SxStyleProp } from 'theme-ui';
import { HighLightedTable } from '../../components';
import { HighLight } from '../../models/highlight';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { GroupedDataGraphResponse } from '../../api';
import { generalColors } from '../../utils';

interface HighlightsBalanceProps {
  data: GroupedDataGraphResponse[];
}

const balanceCss = (balance: number): SxStyleProp => ({
  color: balance > 0 ? generalColors.SUCCESS : generalColors.FAIL
});

const highlightsWrapperCss: SxStyleProp = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  flexWrap: 'wrap'
};
export const HighlightsBalance = (props: HighlightsBalanceProps) => {
  const { data } = props;
  const factoryData = data.filter(d => d.xName === 'Fabrica');
  const branchesData = data.filter(d => d.xName !== 'Fabrica');

  if (data && !!data.length) {
    //calculate total income and expenses/outcome
    const branchesHighlight: HighLight[] = getBalanceHighlights(branchesData);
    const factoryHighlight: HighLight[] = getBalanceHighlights(factoryData);

    return (
      <div sx={highlightsWrapperCss}>
        <HighLightedTable title="Resumen Sucursales" highlights={branchesHighlight} />
        <HighLightedTable
          sx={{ ml: ['0', '2rem'] }}
          title="Resumen Fabrica"
          highlights={factoryHighlight}
        />
        {branchesData.map(b => {
          const highlight = getBalanceHighlights([b]);
          return (
            <HighLightedTable
              sx={{ ml: ['0', '2rem'] }}
              title={`Resumen ${b.xName}`}
              highlights={highlight}
            />
          );
        })}
      </div>
    );
  }
  return null;
};

function getBalanceHighlights(data: GroupedDataGraphResponse[]) {
  let income = 0;
  data.forEach(x => {
    income += x.yData?.filter(y => y.yKey === 'Ingresos')[0].yValue ?? 0;
  });
  let expenses = 0;
  data.forEach(x => {
    expenses += x.yData?.filter(y => y.yKey === 'Egresos')[0].yValue ?? 0;
  });
  let balance = income - expenses;

  const getArrow = (balance: number) => {
    if (balance > 0) {
      return <ArrowUpward sx={{ fontSize: 'inherit' }} />;
    }
    return <ArrowDownward sx={{ fontSize: 'inherit' }} />;
  };

  const highlights: HighLight[] = [
    { title: 'Ingresos', value: `\$${income}` },
    { title: 'Egresos', value: `\$${expenses}` },
    {
      title: 'Balance',
      value: (
        <span sx={balanceCss(balance)}>
          {getArrow(balance)}${balance}
        </span>
      )
    }
  ];
  return highlights;
}
