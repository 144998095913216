import { CartesianMarkerProps } from '@nivo/core';
import { LineDatum, LineSerieData } from '@nivo/line';
import { LatLng } from 'geolocation-utils';
import { DaySale, PeriodsComparison, SalesByDay } from '../api';
import { ComparisonPeriods } from '../pages';
import { colors } from './colors';

export const formatTotalSalesForGraph = (
  data: SalesByDay[],
  areUnits: boolean = false
): LineSerieData[] => {
  return data.map(item => {
    const lineSerieData: LineSerieData = {
      id: item.pointOfSale as string,
      data:
        item?.sales?.map(sale => {
          const lineDatum: LineDatum = {
            x: sale.date,
            y: areUnits ? parseInt(`${sale.total}`) : sale?.total?.toFixed(2)
          };
          return lineDatum;
        }) ?? []
    };
    return lineSerieData;
  });
};

export const formatTotalSalesForComparisonGraph = (
  data: PeriodsComparison[],
  period: ComparisonPeriods
): LineSerieData[] => {
  let series: LineSerieData[] = [];
  data.forEach(item => {
    const currentSerieData: LineSerieData = {
      id: item.pos + (period === 'month' ? ' (este mes)' : ' (esta semana)'),
      data:
        item?.currentPeriodSales?.map(sale => getLineDatumForComparison(sale, period)) ??
        []
    };
    const previousSerieData: LineSerieData = {
      id: item.pos + (period === 'month' ? ' (mes pasado)' : ' (semana pasada)'),
      data:
        item?.previousPeriodSales?.map(sale => getLineDatumForComparison(sale, period)) ??
        []
    };
    series.push(currentSerieData);
    series.push(previousSerieData);
  });
  return series;
};

function getLineDatumForComparison(sale: DaySale, period: ComparisonPeriods) {
  const lineDatum: LineDatum = {
    x:
      period === 'month'
        ? sale?.date?.slice(0, 2)
        : formatCurrentWeekDay(sale?.date as string),
    y: sale?.total?.toFixed(2)
  };
  return lineDatum;
}

export const getAvgMarkers = (
  data: SalesByDay[],
  areUnits: boolean = false
): CartesianMarkerProps[] => {
  let markers: CartesianMarkerProps[] = [];
  data.forEach(posSales => {
    let sum = 0;
    posSales?.sales?.map(sd => {
      sum = sum + (sd?.total ?? 0);
    });
    let avg = (sum / (posSales?.sales?.length ?? 1)).toFixed(2);
    markers.push({
      axis: 'y',
      value: avg,
      //@ts-ignore
      lineStyle: { stroke: colors[posSales.pointOfSale] }
    });
  });
  return markers;
};

export const formatCurrentWeekDay = (graphText: string): string => {
  const weekDays = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado'
  ];
  const dayAndMonth = graphText.split('/');
  const date = new Date(
    parseInt(dayAndMonth[2]),
    parseInt(dayAndMonth[1]) - 1,
    parseInt(dayAndMonth[0])
  );
  return weekDays[date.getDay()];
};

export const formatLocation = (rawLocation?: string): LatLng => {
  // for  /@-24.7796056,-65.4057221,17z/
  // it will return ["/@-24.7796056", "-65.4057221", "17z/"]
  if (!rawLocation) throw Error('Location was not defined!');
  const destruct = rawLocation.split(',');
  const lat = Number(destruct[0].replace('/', '').replace('@', ''));
  const lng = Number(destruct[1]);
  if (isNaN(lat) || isNaN(lng)) {
    console.warn(`Location is not a number:${lat}, ${lng}`);
    return { lat: -1, lng: -1 };
  }
  return {
    lat: Number(destruct[0].replace('/', '').replace('@', '')),
    lng: Number(destruct[1])
  };
};
