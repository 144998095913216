/** @jsx jsx */
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { jsx } from 'theme-ui';

interface DateInputProps {
  onChange: (date: MaterialUiPickersDate) => void;
  value: ParsableDate;
  defaulTvalue?: ParsableDate;
  label?: string;
  className?: string;
}

export function DateInput(props: DateInputProps) {
  const { onChange, ...rest } = props;
  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date) {
      try {
        date.toISOString().substring(0, 10);
        onChange(date as Date);
      } catch (error) {
        return;
      }
    }
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        KeyboardButtonProps={{
          'aria-label': 'Cambiar fecha'
        }}
        onChange={handleDateChange}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}
