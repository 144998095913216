/** @jsx jsx */
import { jsx } from 'theme-ui';

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Fragment } from 'react';
import * as css from './salesByCategoryFilters';
import { SalesByCategorySummary } from './useSalesByCategory';

interface SalesByCategoryTableProps {
  salesSummary: SalesByCategorySummary;
  pos: string;
}

export function SalesByCategoryTable(props: SalesByCategoryTableProps) {
  const { salesSummary, pos } = props;
  let { totalBase, totalUnits, totalAmount, grandTotal } = salesSummary;
  return (
    <Fragment>
      <Grid container>
        <h2 sx={{ textAlign: 'center', width: '100%' }}>
          Resumen de Ventas: {pos.replace('_', ' ')}{' '}
        </h2>
      </Grid>

      {!!salesSummary.salesData.length && (
        <Grid container spacing={2} sx={{ pt: '1rem' }}>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: '70vh', maxWidth: ['100%', '70vw'], mx: 'auto' }}
          >
            <Table
              stickyHeader
              aria-label="collapsible table"
              size="small"
              sx={css.tableCss}
            >
              <TableHead>
                <TableRow sx={css.tableBorderCss}>
                  <TableCell align="center">
                    <b>Categoria</b>
                  </TableCell>
                  <TableCell sx={css.tableHeaderCss} align="right">
                    <b>Unidades</b>
                  </TableCell>
                  <TableCell sx={css.tableHeaderCss} align="right">
                    <b>Neto</b>
                  </TableCell>
                  {/* <TableCell sx={css.tableHeaderCss} align="right">
                    <b>Impuestos</b>
                  </TableCell> */}
                  <TableCell sx={css.tableHeaderCss} align="right">
                    <b>Total</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesSummary.salesData.map(s => {
                  return (
                    <TableRow key={s.id}>
                      <TableCell align="center">{s.catName}</TableCell>
                      <TableCell align="center">{s.units}</TableCell>
                      <TableCell align="center">${s.base}</TableCell>
                      {/* <TableCell align="center">${s.amount}</TableCell> */}
                      <TableCell align="center">${s.total}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={css.tableHeaderCss}>
                    Totales
                  </TableCell>
                  <TableCell align="center">{totalUnits}</TableCell>
                  <TableCell align="center">${totalBase}</TableCell>
                  {/* <TableCell align="center">${totalAmount}</TableCell> */}
                  <TableCell align="center">${grandTotal}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Fragment>
  );
}
