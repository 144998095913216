import { SxStyleProp } from 'theme-ui';

export const container: SxStyleProp = {
  height: '90vh',
  padding: '1rem',
  overflowY: 'auto',
  position: 'relative'
};

export const select: SxStyleProp = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap'
};

export const cell: SxStyleProp = {
  fontWeight: 'bold',
  color: 'black',
  textAlign: 'center'
};

export const stockButtonMargin: SxStyleProp = {
  marginLeft: '1rem'
};

export const cellAlignCenter: SxStyleProp = {
  textAlign: 'center'
};

export const stockSmallTable: SxStyleProp = {
  width: ['100%', '26rem']
};

export const tableDiv: SxStyleProp = {
  height: '28.125rem'
};
