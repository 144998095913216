/**@jsx jsx */
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { jsx } from 'theme-ui';
import * as css from './customSnackbarCss';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

export interface Props {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: keyof typeof variantIcon;
  verticalAlignment: 'top' | 'bottom';
  horizontalAlignment: 'left' | 'center' | 'right';
  open: boolean;
}

export function CustomSnackBar(props: Props) {
  const {
    className,
    message,
    onClose,
    variant,
    verticalAlignment,
    open: openProp,
    horizontalAlignment,
    ...other
  } = props;
  const Icon = variantIcon[variant];
  return (
    <Snackbar
      anchorOrigin={{
        vertical: verticalAlignment,
        horizontal: horizontalAlignment
      }}
      open={openProp}
      autoHideDuration={6000}
    >
      <SnackbarContent
        className={className}
        aria-describedby="client-snackbar"
        sx={css.variantColor[variant]}
        message={
          <span id="client-snackbar" sx={css.message}>
            <Icon sx={{ ...css.icon, ...css.iconVariant }} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon sx={css.icon} />
          </IconButton>
        ]}
        {...other}
      />
    </Snackbar>
  );
}
