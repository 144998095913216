import { SxStyleProp } from 'theme-ui';

export const wrapper: SxStyleProp = {
  background: 'white',
  padding: '9px 12px',
  border: '1px solid #ccc'
};

export const marginLeft: SxStyleProp = {
  marginLeft: '1.5rem'
};
export const slice = (color: string): SxStyleProp => ({
  color,
  padding: '3px 0'
});
