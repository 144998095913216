/** @jsx jsx */
import {
  Button,
  Container,
  Grid,
  LinearProgress
} from '@material-ui/core';
import React from 'react';
import { Flex, jsx } from 'theme-ui';
import { Filters, PosSelect } from '../../components';
import { Pos } from '../../models';
import * as css from './salesByCategoryFilters';
import { SalesByCategoryTable } from './salesByCategoryTable';
import { useSalesByCategory } from './useSalesByCategory';

export function SalesByCategoryReport() {
  const initalDate = new Date();
  initalDate.setDate(initalDate.getDate() - 1);
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date>(initalDate);
  const [selectedToDate, setSelectedToDate] = React.useState<Date>(new Date());
  const [pos, setPos] = React.useState<Pos>(Pos.Belgrano);
  const fromDate = selectedFromDate.toISOString().substring(0, 10);
  const toDate = selectedToDate.toISOString().substring(0, 10);

  const handlePosChange = (pos: Pos) => {
    setPos(pos);
  };

  const { salesSummary, isLoading, getSales } = useSalesByCategory(fromDate, toDate, pos);

  return (
    <React.Fragment>
      <Container sx={css.transactionsContainer}>
        <Flex sx={css.transactionsContainerFlex}>
          <Filters
            selectedFromDate={selectedFromDate}
            setSelectedToDate={setSelectedToDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
          />
          <div sx={{ ml: [0, '1rem'], mt: ['1rem', 0] }}>
            <PosSelect onSelect={handlePosChange} value={pos} />
          </div>
          <Grid item lg={2}>
            <Button
              onClick={getSales}
              sx={css.actionButton}
              color="primary"
              variant="contained"
            >
              CONSULTAR
            </Button>
          </Grid>
        </Flex>
        <Grid container> {isLoading && <LinearProgress sx={css.progressBar} />}</Grid>
        {!!salesSummary && <SalesByCategoryTable salesSummary={salesSummary} pos={pos} />}
      </Container>
    </React.Fragment>
  );
}
