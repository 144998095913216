import { SxStyleProp } from 'theme-ui';

export const modal: SxStyleProp = {
  '.MuiDialog-container': {
    margin: '-1.225rem'
  },
  '.MuiDialog-paperWidthSm': {
    width: ['90vw', '50vw']
  }
};

export const iconButton: SxStyleProp = {
  position: 'absolute',
  top: '.5rem',
  right: '.5rem'
};

export const dialogContent: SxStyleProp = {
  padding: '1.5rem'
};
