/** @jsx jsx */
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch
} from '@material-ui/core';
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import { graphTitleCss, loaderCss } from '../../../styles';
import {
  formatTotalSalesForGraph,
  getAvgMarkers,
  userCanSeeFinancialInfo
} from '../../../utils';
import { CustomSnackBar } from '../../customSnackbar';
import { Filters } from '../../filter';
import { GraphContainer } from '../../graphContainer';
import { LineGraph } from '../../lineGraph';
import * as css from './salesByMonthGraphSectionCss';
import { useSalesByMonth } from './useSalesByMonth';

const canSeeFinancial = userCanSeeFinancialInfo();

export function SalesByMonthGraphSection() {
  const initalDate = new Date(new Date().getFullYear(), 0, 1);
  const [showUnits, setShowUnits] = useState<boolean>(!canSeeFinancial);
  const [selectedFromDate, setSelectedFromDate] = useState<Date>(initalDate);
  const [selectedToDate, setSelectedToDate] = useState<Date>(new Date());
  const [showAvg, setShowAvg] = useState<boolean>(false);

  const fromDate = selectedFromDate.toISOString().substring(0, 10);
  const toDate = selectedToDate.toISOString().substring(0, 10);

  const {
    handleQueryClick,
    error,
    setError,
    isLoading,
    totalSales,
    totalUnits
  } = useSalesByMonth(fromDate, toDate);

  return (
    <React.Fragment>
      {error && (
        <CustomSnackBar
          message={error}
          variant="error"
          verticalAlignment="top"
          open={!!error}
          horizontalAlignment="center"
          onClose={() => setError('')}
        />
      )}

      <Grid item xs={12}>
        <GraphContainer>
          <h2 sx={graphTitleCss}>
            {!showUnits ? 'Facturacion' : 'Unidades vendidas'} por mes
          </h2>
          <div sx={css.container1}>
            {canSeeFinancial && (
              <FormControlLabel
                control={
                  <Switch
                    checked={showUnits}
                    onChange={(e, c) => setShowUnits(c)}
                    color="primary"
                  />
                }
                label={`Mostrar ${showUnits ? 'facturacion' : 'unidades'}`}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={showAvg}
                  onChange={(e, c) => setShowAvg(c)}
                  color="primary"
                />
              }
              label={`${showAvg ? 'Ocultar' : 'Mostrar'} promedios`}
              sx={css.salesByMonthML}
            />
          </div>
          <div sx={css.container2}>
            <Filters
              selectedFromDate={selectedFromDate}
              setSelectedToDate={setSelectedToDate}
              setSelectedFromDate={setSelectedFromDate}
              selectedToDate={selectedToDate}
            />
            <Button
              onClick={handleQueryClick}
              sx={css.button}
              color="primary"
              variant="contained"
            >
              CONSULTAR
            </Button>
          </div>
          {isLoading && <CircularProgress sx={loaderCss} color="primary" />}
          {!!totalSales.length && !!totalUnits.length && (
            <React.Fragment>
              {!!totalSales.length && (
                <LineGraph
                  data={formatTotalSalesForGraph(showUnits ? totalUnits : totalSales)}
                  yFormat={value =>
                    showUnits ? parseInt(value.toString(), 10) : `$${value}`
                  }
                  isMoney={!showUnits}
                  markers={
                    showAvg
                      ? getAvgMarkers(showUnits ? totalUnits : totalSales, showUnits)
                      : []
                  }
                  lineSpacing={90}
                />
              )}
            </React.Fragment>
          )}
        </GraphContainer>
      </Grid>
    </React.Fragment>
  );
}
