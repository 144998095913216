/** @jsx jsx */
import { Button } from '@material-ui/core';
import { Flex, jsx } from 'theme-ui';
import { useModal } from '../../../hooks';
import { ExpensesCategoriesForm } from '../expensesCategoriesForm';
import { useCategoryUpdate } from './useCategoryUpdate';
import { addCategoryFlex } from './expensesCategoriesTableCss';

export function AddCategoryAction() {
  const { Modal, openModal } = useModal();
  const { handleCategoryUpdate } = useCategoryUpdate();

  return (
    <Flex sx={addCategoryFlex}>
      <Modal title="Resgistrar categoria">
        <ExpensesCategoriesForm onSubmit={handleCategoryUpdate} />
      </Modal>
      <Button color="primary" variant="contained" onClick={openModal}>
        Agregar Categoria
      </Button>
    </Flex>
  );
}
