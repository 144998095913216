export enum Routes {
  Dashboard = '/',
  Login = '/login',
  MonthlySales = '/salesByMonth',
  SalesLocations = '/salesLocations',
  Stock = '/stock',
  Comparisons = '/comparison',
  ExpensesCategories = '/expensescat',
  Expenses = '/expenses',
  GeneralReport = '/generalReport',
  StockReport = '/stockReport',
  TransactionsReport = '/transactionReport',
  PaymentsReport = '/paymentsReport',
  ProductsSold = '/productsSold',
  OrdersLocations = '/ordersLocation',
  Certification = '/certification',
  SalesByCategorySummary = '/salesByCategory'
}
