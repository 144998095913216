export enum Pos {
  Bicentenario = 'BICENTENARIO',
  Belgrano = 'BELGRANO',
  GrandBourg = 'GRANDBOURG',
  Freezados = 'FREEZADOS',
  PaseoSalta = 'PASEO_SALTA',
  ZonaSur = 'ZONA_SUR',
  Factory = 'FABRICA',
  Todos = 'TODOS'
}

export const posIdToEnumMap: {[key: number]: Pos} = {
  1: Pos.Factory, //	"Fabrica"
  2: Pos.Belgrano, //"Belgrano"
  3: Pos.Bicentenario, //"Bicentenario"
  4: Pos.GrandBourg, //"Grand Bourg"
  5: Pos.Freezados, //"Freezados"
  6: Pos.PaseoSalta, //"Paseo Salta"
  7: Pos.ZonaSur //"Zona Sur"
};
