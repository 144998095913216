/** @jsx jsx */
import { jsx } from 'theme-ui';
import { NavMenu } from '../navMenu';
import { SxStyleProp } from 'theme-ui';

//Component Styles
const layoutWrapper: SxStyleProp = {
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden'
};
const mainStyle: SxStyleProp = {
  flexGrow: 1,
  overflow: 'auto'
};
//

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div sx={layoutWrapper}>
      <NavMenu />
      <main sx={mainStyle}>{children}</main>
    </div>
  );
}
