export enum PosCategories {
  EMPANADAS = 'Empanadas',
  EMPABURGUER = 'Burger',
  COMBOS = 'Combos',
  CANASTAS = 'Canastas',
  DRINKS = 'Bebidas',
  PIZZA = 'Pizzas',
  ECHANGO = 'EChango',
  EXTRAS = 'Adicionales'
}
