/** @jsx jsx */
import { TablePagination, Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { ExpensesApi, PagedResultOfExpenseDTO } from '../../../api';
import { useExpensesState } from '../../../contexts';
import { useFetchEntity } from '../../../hooks';
import { getBeginingOfTheMonth, getTodayDate } from '../../../utils';
import { Combobox, DateInput } from '../../../components';

export function useExpensesFitlerAndPagination() {
  const [expState, setExpState] = useExpensesState();
  const [dateFrom, setDateFrom] = useState(getBeginingOfTheMonth());
  const [dateTo, setDateTo] = useState(getTodayDate('end'));
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [branchId, setBranchId] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [expenses, isLoadingExpenses] = useFetchEntity<PagedResultOfExpenseDTO>(
    // @ts-ignore as TS doesn't have `bind` type definitions for more than 4 parameters 😢
    // see 👉 https://stackoverflow.com/questions/57072381/typescript-bind-gives-type-error-when-using-more-than-4-parameters
    ExpensesApi.getExpenses.bind(
      ExpensesApi,
      categoryId,
      branchId,
      dateFrom,
      dateTo,
      pageNumber,
      rowsPerPage
    ),
    [dateTo, dateFrom, categoryId, branchId, pageNumber, rowsPerPage]
  );

  useEffect(() => {
    expState.expenses.values = expenses?.results ?? [];
    expState.expenses.isLoading = isLoadingExpenses;
    expState.expenses.totalAmount = expenses?.totalAmount;
    setExpState(expState);
  }, [expenses?.results?.length, isLoadingExpenses]);

  function ExpensesFilters() {
    return (
      <Grid container spacing={1}>
        <Grid item lg={3} md={6}>
          <DateInput
            value={dateFrom}
            label="Desde"
            onChange={d => setDateFrom(d as Date)}
          />
        </Grid>
        <Grid item lg={3} md={6}>
          <DateInput value={dateTo} label="Hasta" onChange={d => setDateTo(d as Date)} />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <Combobox
            options={expState.categories.values}
            loading={expState.categories.isLoading}
            value={expState.categories.values.find(c => c.id === categoryId)}
            getOptionLabel={o => o.name ?? ''}
            onChange={(e, v) => setCategoryId(v?.id as number)}
            label="Categoria"
          />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <Combobox
            options={expState.branches.values}
            loading={expState.branches.isLoading}
            value={expState.branches.values.find(b => b.id === branchId)}
            getOptionLabel={o => o?.name as string}
            onChange={(e, v) => setBranchId(v?.id as number)}
            label="Sucursal"
          />
        </Grid>
      </Grid>
    );
  }

  function ExpensesPagination() {
    return (
      <TablePagination
        rowsPerPageOptions={[30, 50, 100]}
        count={expenses?.rowCount ?? 0}
        component="span"
        labelRowsPerPage="Registros por pagina"
        colSpan={7}
        rowsPerPage={expenses?.pageSize ?? 0}
        page={pageNumber}
        onChangePage={(e, p) => setPageNumber(p)}
        onChangeRowsPerPage={e => setRowsPerPage(parseInt(e.target.value, 10))}
      />
    );
  }

  return { expState, ExpensesFilters, ExpensesPagination };
}
